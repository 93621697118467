import { useEffect, useState } from "react";
import user from "skin/store/user";


//Состояние сообщений
export default function useMsg(refD, close) {
	const [ping, setPing] = useState(null)
	const [show, setShow] = useState(false);
	const [count, setCount] = useState(0)
	const clos = close ?? (_ => setShow(false))
	const click = _ => {
		refD.current = show ? true : false
		setShow(!show)
	};
	useEffect(() => {
		const date = new Date(localStorage.getItem('msg') ?? null)
		user.msg(date)
		.then(r => {console.log('r', r); setCount(r)})
		.catch(console.error)
		setTimeout(_ =>setPing(new Date()), 60000)
	}, [ping])

	return {click, clos, show, count, setCount}
}