import { useState } from 'react';
import Title from '../../cmp/title';
import Field from '../../cmp/field';
import Btn from '../../cmp/btn';
import Mes from '../../cmp/mes';
import { type } from 'skin/event';
import api from 'skin/http';
import './style.css'
import m from 'skin/store/mb';
import { observer } from 'mobx-react-lite';
import FieldD from 'skin/entry/cmp/fieldD';


const Win = (props) => {
	const mb = m.isMobile;

	const [login, setLogin] = useState('');
	const [pass1, setPass1] = useState('');
	const [pass2, setPass2] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [company, setCompany] = useState('');
	const [town, setTown] = useState('');
	const [code, setCode] = useState('');
	const [where, setWhere] = useState('');
	const [mes, setMes] = useState('');

	let clsFields = 'login-main-fields';

    if(mb) clsFields += ' mb-fields';

	return (
		<div style= {mb ? {} : {paddingTop:'5%'}} className='login-content'>
		<div style = {mb ? {padding:"24px"} : {}} className='login-main-container'>
			<div >
			<div style={{display:'flex', justifyContent:'space-between', alignItems:'center', paddingBottom:'10px'}}>	
				<Title name = {'Регистрация'} />
				{mb && <img 
						className={'signup-close'}
						src='/skin/img/close_circle.svg' alt='x' 
						onClick={log} />}
				
			</div>
			<form  style={mb ? {maxHeight:'50vh', overflow:'auto'} : {}} >
				<div>
				<div className={clsFields}>
					<Field
						title="Логин"
						placeholder="Введите логин"
						img="/task/img/login_p.svg"
						act={setLogin}
						type="text"
						required
					/>
					<Field
						title="Электронная почта"
						placeholder="Введите почту при регистрации"
						img="/task/img/email_p.svg"
						act={setEmail}
						type="email"
						required
					/>
				</div>
				<div className={clsFields}>
					<Field
						title="Пароль"
						placeholder="Введите пароль от 8 до 16 символов"
						img="/task/img/pass_p.svg"
						act={setPass1}
						type="password"
						required
					/>
					<Field
						title="Наименование организации"
						placeholder="Введите наименование организации"
						img="/task/img/company_p.svg"
						act={setCompany}
						type="text"
						required
					/>
				</div>
				<div className={clsFields}>
					<Field
						title="Повторите пароль"
						placeholder="Введите пароль от 8 до 16 символов"
						img="/task/img/pass_p.svg"
						act={setPass2}
						type="password"
						required
					/>
					<FieldD
						id="town"
						title="Населенный пункт"
						placeholder="Выберите из"
						act={setTown}
						dCode="kladr"
						dText="Субъекты"
						required
						disabled
					/>
				</div>
				<div className={clsFields}>
					<Field
						id="phone"
						title="Номер телефона"
						placeholder="+7(___)___-__-__"
						img="/task/img/phone_p.svg"
						act={setPhone}
						type="phone"
						required
						mask={"+7(999) 999-99-99"}
					/>
					<FieldD
						id="where"
						title="Откуда вы узнали о нас"
						placeholder="Выберите из"
						act={setWhere}
						dText="Источники"
						dCode="where"
						disabled
					/>
				</div>
				<Mes text={mes} />
				</div>
				{!mb && <div className={clsFields}>
					<Btn
						title="Зарегистрироваться"
						act={signup}
					/>
					<Btn title="Отмена" act={log} />
				</div>}
			</form>		
			</div>
			{mb && 
				<div className={clsFields}>
					<div className='login-main-btn-group'>
						<Btn
							title="Зарегистрироваться"
							act={signup}
						/>
						<Btn title="Отмена" act={log} />
					</div>
				</div>}	
		</div>
		</div>
	);

	function log() {
		type('login');
	}

	function signup() {
		const reg = /^[\w]*$/;
		if (!login) return setMes('Заполните логин!');
		if (!reg.test(login))
			return setMes(
				'Логин должен содержать только латинские символы и цифры!'
			);
		if (!pass1) return setMes('Заполните пароль!');
		if (pass1 !== pass2) return setMes('Пароли должны совпадать!');
		if (pass1.length < 8 || pass1.length > 16)
			return setMes('Пароль должен содержать от 8 до 16 символов!');
		if (!email) return setMes('Заполните электронную почту!');
		if (!company) return setMes('Заполните наименование организации!');
		if (!town) return setMes('Выберите населенный пункт!');
		setMes('');
		const o = {
			login,
			password1: pass1,
			password2: pass2,
			phone,
			mail: email,
			company,
			code,
			town,
			where: Array.isArray(where) ? where[0] : where,
		};
		o.phone = +o.phone.replace(/\D/g, '');
		if(o.phone.length < 11) return setMes('Номер телефона введён не полностью');
		api.post('api/auth/signup', o)
			.then((res) => {
				alert(
					'Учетная запись создана!\nДля продолжения авторизируйтесь.'
				);
				setTimeout(() => type('login'), 500);
			})
			.catch((e) => {
				
				console.log('Error ', e.response?.data);
				let msg = e.response?.data?.message;
				console.log(e);
				if (!msg) return;
				if (!Array.isArray(msg)) msg = [msg];
				setTimeout(() => setMes(msg), 1000);
			});
	}
};

export default observer(Win);
