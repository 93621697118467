import cnfTask from 'config_task';
import { makeAutoObservable, runInAction } from 'mobx';
import { space, stackClear } from 'skin/event';
import api from 'skin/http';

class Data {
	s = {
		msg: []
	};

	constructor(props) {
		makeAutoObservable(this);
	}

	// Получение данных пользователя
	get() {
		Promise.all([
			api.get('api/data/employee'),
			api.get('api/data/count/product'),
		])
			.then(([resE, resP]) => {
				runInAction(() => {
					const msg = this.s.msg
					this.s = resE.data.result;
					this.s.product = resP.data.result;
					this.s.msg = msg
				});
			})
			.catch((e) => {
				console.log('User get error', e);
			});
	}

	form() {
		const o = {
			type: 'form',
			title: 'Личный кабинет',
			data: {
				code: cnfTask.userForm ?? 'employee',
				info: [this.id],
			},
		};
		stackClear();
		space(o, true);
	}
	get list() {
		return this.s.msg ?? []
	}
	// дата последнего просмотренного сообщений
	get last() {
		const s = this.s.msg
		return s?.at(0)?.date ?? ''
	}
	get id() {
		return this.s._id ?? '';
	}

	get code() {
		return this.s.code ?? '';
	}
	get tar() {
		return this.s.tar ?? '';
	}
	get companyId() {
		return this.s.companyId ?? '';
	}

	get short() {
		return this.s.short ?? false;
	}
	get adm() {
		return this.s.adm ?? false;
	}

	get blc() {
		return this.s.blc ?? 0;
	}
	get payDate() {
		return this.s.payDate ?? '';
	}
	// Форрмирование Имени пользователя
	get name() {
		const s = this.s;

		const name = s.name?.first;
		const surname = s.name?.last;
		let r = name ? name.charAt(0) + '. ' : '';
		r = r && surname ? r + surname : '';
		if (r === '') r = s.login;
		return r;
	}

	// Формирование url аватара сотрудника
	get avatar() {
		const s = this.s;
		return this.s.img
			? 'url(' + cnfTask.url + 'company/' + s.code + '/img/' + s.img + ')'
			: 'url(skin/img/avatar.svg)';
	}
	// Формирование url логотипа компании
	get logo() {
		return this.s.logo;
	}

	get product() {
		return this.s.product ?? '';
	}

	chPrd(value) {
		this.s.product = value;
	}

	msg(date) {
		return new Promise((resolve, reject) => {
			const opt = {
				method: 'GET',
				url: 'api/data/message',
				headers: {
					nowait: true
				}
			}
			if (date) opt.params = { date }
			api(opt)
			.then(res => {
				console.log('res', res.data)
				if (date) return resolve(res.data.length)
				runInAction(() => {
					this.s.msg = res.data
				})
			})
			.catch(reject)
		})
	}
}
const d = new Data()
export default d;
